<template>
<CRow>
    <CCol class="">
        <CCard>
            <CCardHeader class="mb-0 pb-0" id="card-header">
                <p id="summary-title" class="card-title">Performance Summary</p>
            </CCardHeader>
            <CCardBody>
                <CRow>
                <CCol>
                    <p class="card-text data-heading card-text-light"> Wand Used Correctly</p>
                  <p id="wand-status" class="cart-text-dark data-display">{{wandUsedCorrectly}}</p>
                  <p class="card-text-light data-heading"> Total Wand Drops</p>
                  <p id="total-wand-drops" class="data-display">{{performanceSummaryData.totalWandDrops}}</p>
                </CCol>

                <CCol>
                    <p class="card-text card-text-light data-heading"> Safe Pressure Range</p>
                  <p id="wand-status" class="cart-text-dark data-display">{{safePressureRange}}</p>
                  <p class="card-text-light data-heading">Total E-Stops</p>
                  <p id="total-e-stops" class="data-display">{{performanceSummaryData.totalEstops}}</p>
                </CCol>
                </CRow>
                <CCol>
                    <CScoreCard
                  class="p-0"
                  :score="performanceSummaryData.score"
                  title="Safety Score"
                  :message="performanceSummaryData.message"
                />
                    
                </CCol>
            </CCardBody>
        </CCard>
    </CCol>

</CRow>
    
</template>


<script>

import CReportCard from "./CReportCard"; 
import CScoreCard from "./CScoreCard"; 

const defaultData = ()=>{
            return {
            wandUsed: true,
            totalWandDrops: 0,
            totalEstops: 0,
            pressureExceeded: false,
            score: 0,
            message:undefined,
            }
        };
export default {
    name: "PerformanceSummary", 
    extends: CReportCard,
    components:{
        CScoreCard,
    },
    props:{
        performanceSummaryData:{
            type:Object, 
            default:defaultData,
        }
    },
    data() {
        return {
            
        }
        
    },
    computed:{
        
        // TODO: this Logic should actually come from the server and not be performed in the front-end code!
        wandUsedCorrectly(){
            // If the wand wasn't detected at all, then definitely wasn't used correctly!
            if(!this.performanceSummaryData.wandUsed){
                return this.convertBooleanToHumanString(false);
            }
            else if(this.performanceSummaryData.wandIdleWithWaterOn){ // This condition is not allowed. 
                return this.convertBooleanToHumanString(false);
            }    
            return this.convertBooleanToHumanString(true);
            
        },
        safePressureRange(){
            return this.convertBooleanToHumanString(!this.performanceSummaryData.pressureExceeded); 
        }
    }

}
</script>



