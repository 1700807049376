<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader class="pb-0 mb-0" id="card-header">
            <p id="job-data-title" class="card-title">Job Data</p>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <div class="col-sm info-box-content">
                <p class="card-text-light data-heading">Max Water Pressure</p>
                <p id="max-water-pressure" class="card-text-dark data-display">
                  {{ jobData.maxWaterPressure }}
                </p>
              </div>
              <div class="col-sm info-box-content">
                <p class="card-text-light data-heading">Total Water On Time</p>
                <p id="total-water-on" class="card-text-dark time-display">
                  {{ convertMsToString(jobData.totalWaterTime) }}
                </p>
              </div>
              <div class="col-sm info-box-content">
                <p class="card-text-light data-heading">Total Vac Time</p>
                <p class="card-text-dark data-display c">
                  {{ convertMsToString(jobData.totalVacTime) }}
                </p>
              </div>
            </CRow>
            <CRow>
              <div class="col-sm-4 info-box-content">
                <p class="card-text-light data-heading">Time Above 2800 PSI</p>
                <p id="time-above-max" class="card-text-dark data-display c">
                  {{ convertMsToString(jobData.timeAboveMaxPressure) }}
                </p>
              </div>
              <div class="col-sm info-box-content">
                <p class="card-text-light data-heading">Time Above 3000 PSI</p>
                <p id="time-above-critical" class="card-text-dark data-display">
                  {{ convertMsToString(jobData.timeAboveCriticalPressure) }}
                </p>
              </div>
            </CRow>

            <CRow>

              <GrafanaEmbeddedPanel
                :src="graphData"
                :orgId="orgId"
                :to="start"
                :from="end"
                height="450"
                annotations="1"
                class="w-100"
                :key="chartKey"
              >
              </GrafanaEmbeddedPanel>
            </CRow>
            <CRow>
              <CCol>
                <CDataTable
                  hover
                  :items="eventData"
                  :fields="fields"
                  sorter
                  dense
                  responsive
                >
                  <template #start="{ item }">
                    <td>
                      {{ showDate(item) }}
                    </td>
                  </template>
                  <template #event="{ item }">
                    <td>
                        <CBadge v-if="showStatus(item)" :color="showStatus(item)">
                        <p style="font-size:10pt">{{ item.event }}</p>
                        </CBadge>
                        <p v-else>
                        {{ item.event }}
                        </p>
                        <!-- TODO: Highlight Color on Critical events" -->
                      
                    </td>
                  </template>

                  <template #show_details="{item, index}">
                    <td class="py-2">
                      <CButton
                        color="danger"
                        variant="outline"
                        square
                        size="sm"
                        @click="getJobById(item, index)"
                      >
                        {{ Boolean(item._toggled) ? "less" : "more" }}
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>

import CReportCard from "../reports/CReportCard"
import GrafanaEmbeddedDashboard from "../grafana/GrafanaEmbeddedDashboard"
import GrafanaEmbeddedPanel from "../grafana/GrafanaEmbeddedPanel";

const defaultChart = "https://demo1.aarcomm.io/api/grafana/d/7_3_iVTVz/test-clean-harbors-dashboard-v3?orgId=1&viewPanel=52"; 

export default {
  name: "JobDataView",
  extends: CReportCard, 
  props:{
    orgId:String,
  },
  components: {
    GrafanaEmbeddedDashboard,
    GrafanaEmbeddedPanel

  },
  data() {
    return {
      chartKey: 0, 
      device: "", 
      jobData: {
        start: undefined, 
        end: undefined, 
        maxWaterPressure: 0,
        timeAboveMaxPressure: 0,
        timeAboveCriticalPressure: 0,
        totalVacTime: 0,
        totalWaterTime: 0,
        graphData: [],
        eventData: []
      },
      fields: [
        { key: "time", label: "Time" },
        { key: "event", label: "Event" }
      ]
    };
  },
  computed:{
    start(){
      return this.jobData?this.jobData.start: Date.now(); 
    }, 
    end(){
      return this.jobData?this.jobData.end: Date.now(); 
    },
    
      eventData(){
          if(!this.jobData){
              return [];
          }
          return this.jobData.eventData; 
      },
      graphData(){
        let src; 
          if(!this.jobData){
              return ""; 
          }
          else 
          {
            const device = this.device;
            console.log(this.jobData); 
              let temp = this.jobData.graphData.find(x=>x.name=="pressure"); 
              if(temp)
              {
  
                let src = temp.src; 
                
                  // TODO: Append Variables Here 
                  src += `&var-annotation=1&var-annotation=2&var-deviceID`
                  src += `=${device}`;

                  console.log(src);
                return src;
              }
              else{
                const device = this.device;
                  // TODO: Append Variables Here 
                  src += `&var-annotation=1&var-annotation=2&var-deviceID`
                  src += `=${device}`;

                  console.log(src);
                return defaultChart;
              }
                 
            
          }
      }
  },
  methods: {
    setJobData(data) {
      console.log("setting job data"); 
      console.log(data); 
      Object.assign(this.$data.jobData,data); // Unsafe!
      this.device = data.device; 
      this.chartKey +=1; 

    },
    showStatus(item){
      let ret = ""; 
      let temp = item.event.toString().toLowerCase(); 
      if(temp.includes("wand entered idle state with water on")){
          return "warning"
      }
      else if(temp.includes("water on") && temp.includes("idle state")){
        return "warning"
      }
      return ret; 

        
    },
    convertMsToString: function(elapsed) {
      let temp = this.$moment.utc(elapsed);
      let hours = elapsed / (1000 * 3600);

      // TODO; Format may vary depending on region;
      let timeUnits = { hour: "hr", min: "m", sec: "s" };

      // TODO: Load local time units from regional settings for organization

      let myTimeString = "";
      if (hours > 1.0) myTimeString += temp.format("H") + timeUnits.hour + " "; // Must be 24 Hour Format  myTimeString +=

      myTimeString += temp.format("m") + timeUnits.min + " ";
      myTimeString += temp.format("ss") + timeUnits.sec + " ";

      return myTimeString; // Only Return the Hours and Seconds.
    }
  }
};
</script>

<style scoped>

.table-heading {
  text-transform: uppercase;
}
</style>