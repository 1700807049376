
<template>
  <div class="job-report-view">
        <CCard v-if="geofenceMessage"
          :collapsed="true"
          :collapsible="true"
          border-color="warning"
          
          class="admin-card"
          
        >
           <CCardHeader >
            <CIcon name="cil-bell" />
            <span> Event: {{ geofenceMessage}} </span>
            
          </CCardHeader>
            
            <CCardBody class="" @dblclick="handleViewClick(item)">
              <CMedia
                class=""
                :aside-image-props="{
                  src: '/images/Mapbox_dark_sample.jpg',
                  height: 100,
                  width: 175,
                }"
              >
                <h4>
                  <span class="p-0">{{ geofenceMessage }}</span>
                </h4>
                <span class="p-0">
                  <CGeocode :lat="siteLocation.lat" :lon="siteLocation.lng"
                /></span>
              </CMedia>
            </CCardBody>
          
        </CCard>
    <JobSummary
      :jobSummaryData="jobSummaryData"
      :truckID="truckNumber"
      :siteAddress="siteAddress"
      :location="siteLocation"
    >
    </JobSummary>
    <PerformanceSummary
      class="pt-2"
      :performanceSummaryData="performanceSummaryData"
    >
    </PerformanceSummary>
    <JobDataView :orgId="$auth.orgId" ref="jobdata"> </JobDataView>
  </div>
</template>

<script>
import JobSummary from "@/components/reports/JobSummary";
import PerformanceSummary from "@/components/reports/PerformanceSummary";
import JobDataView from "@/components/reports/JobDataView";
import CGeocode from "@/components/base/CGeocode";
import  {reportsDashboards} from "@/data/appData";






export default {
  name: "JobReportView",
  components: {
    PerformanceSummary,
    JobSummary,
    JobDataView,
    CGeocode,
  },
  props: {},
  data() {
    return {
      geofenceMessage: "", 
      title: "Job Summary July 22, 2020",
      reportDate: "",
      branchID: undefined,
      jobID: undefined,
      customerID: undefined,
      truckID: "717422",
      deviceID: undefined,
      siteAddress: {
        street: undefined,
        city: undefined,
        province: undefined,
      },
      siteLocation: {
        lat: 0.0,
        lng: 0.0,
        region: undefined,
      },

      operators: [],
      jobSummaryData: {
        start: undefined,
        end: undefined,
        totalJobTime: undefined,
        totalWaterTime: undefined,
      },
      performanceSummaryData: {
        wandUsed: false,
        totalWandDrops: 0,
        totalEstops: 0,
        pressureExceeded: false,
        score: 0,
        message: undefined,
      },
      jobData: {
        maxWaterPressure: 0,
        timeAboveMaxPressure: 0,
        timeAboveCriticalPressure: 0,
        totalVacTime: 0,
        totalWaterTime: 0,
        graphData: [{ item: "pressure", src: "" }],
        eventData: [],
      },
      geofence: [],
    };
  },
  computed: {
    truckNumber() {
      return this.truckID.toString();
    },
    
   
  },
  methods: {
    getChartData(jobData){

      let branch_id = jobData.branch; 

      let src = "";
      if(branch_id === "1"){
        // Get Calgary Branch Report 
        src = reportsDashboards["1"]; // defaultDashboard;
      }
      else{
        src = reportsDashboards["2"]; // defaultDashboard;
      }
      

      let graphData = [
        {item:"pressure", name: "pressure", src: src },
      ]

      

      return graphData; 
    }, 
    getGeofenceMessage() {
      try {
        console.log(this.$data);
        let temp
        if (this.$data.data) {
          temp = this.$data.geofenceData || this.$data.data.geofenceData;
          if (temp) {
            return temp[0].message;
          }
        } else if (this.$data.metadata && this.$data.metadata.geofence) {
          temp = this.$data.metadata.geofence;
          return temp[0].message;
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    },
    copyJobSummaryData(data) {
      this.siteAddress = data.siteAddress;
    },
    /** To Be Set Externally */
    setReportData(temp) {
      console.log(temp);

      
      // todo: this seems hacky. should prob find another way to do this.
      Object.assign(this.$data, temp); // Not Safe because API Response formate may be invalid!!
      
       
      console.log(this.$data); 

      this.geofenceMessage =  this.getGeofenceMessage(); 


      const graphData = this.getChartData(temp); 
      
      // Get Graph Data; 
      temp.jobData.device = this.$data.device || this.deviceID;
      temp.jobData.start = this.$data.jobSummaryData.start;
      temp.jobData.end = this.$data.jobSummaryData.end; 
      temp.jobData.graphData = graphData; 

      console.log(temp); 

      
      this.$refs.jobdata.setJobData(temp.jobData);
    },
  },
  mounted() {
    try {
      /*
            this.$api.getReports({id:362}).then((res)=>{
                if(res.status==200){
                    let temp = res.data; 
                    console.log(temp); 
                    // Copy the Job Summary 
                    this.copyJobSummaryData(temp);  // Safer 
                    Object.assign(this.$data, temp);  // Not Safe because API Response formate may be invalid!!
                    

                    // Copy the Performance Suppary 

                    // Copy the Job Data 

                    this.$refs.jobdata.setJobData(temp.jobData); 


                }
            });
            */
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style scoped> 
.job-report-view >>> #card-header {
  background-color: var(--light);
}

.job-report-view >>> .card-title{
  color: var(--dark);
  font-weight: bold;
}

.job-report-view >>> .data-display {
  font-size: 18px;
}

.job-report-view >>> .time-display {
  font-size: 18px;
}

.job-report-view >>> .text-display {
  font-size: 18px;
}
</style>