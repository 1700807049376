var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    { staticClass: "pb-0 mb-0", attrs: { id: "card-header" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "card-title",
                          attrs: { id: "job-data-title" }
                        },
                        [_vm._v("Job Data")]
                      )
                    ]
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c("CRow", [
                        _c("div", { staticClass: "col-sm info-box-content" }, [
                          _c(
                            "p",
                            { staticClass: "card-text-light data-heading" },
                            [_vm._v("Max Water Pressure")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "card-text-dark data-display",
                              attrs: { id: "max-water-pressure" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.jobData.maxWaterPressure) + " "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col-sm info-box-content" }, [
                          _c(
                            "p",
                            { staticClass: "card-text-light data-heading" },
                            [_vm._v("Total Water On Time")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "card-text-dark time-display",
                              attrs: { id: "total-water-on" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.convertMsToString(
                                      _vm.jobData.totalWaterTime
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col-sm info-box-content" }, [
                          _c(
                            "p",
                            { staticClass: "card-text-light data-heading" },
                            [_vm._v("Total Vac Time")]
                          ),
                          _c(
                            "p",
                            { staticClass: "card-text-dark data-display c" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.convertMsToString(
                                      _vm.jobData.totalVacTime
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("CRow", [
                        _c(
                          "div",
                          { staticClass: "col-sm-4 info-box-content" },
                          [
                            _c(
                              "p",
                              { staticClass: "card-text-light data-heading" },
                              [_vm._v("Time Above 2800 PSI")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "card-text-dark data-display c",
                                attrs: { id: "time-above-max" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.convertMsToString(
                                        _vm.jobData.timeAboveMaxPressure
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "col-sm info-box-content" }, [
                          _c(
                            "p",
                            { staticClass: "card-text-light data-heading" },
                            [_vm._v("Time Above 3000 PSI")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "card-text-dark data-display",
                              attrs: { id: "time-above-critical" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.convertMsToString(
                                      _vm.jobData.timeAboveCriticalPressure
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]),
                      _c(
                        "CRow",
                        [
                          _c("GrafanaEmbeddedPanel", {
                            key: _vm.chartKey,
                            staticClass: "w-100",
                            attrs: {
                              src: _vm.graphData,
                              orgId: _vm.orgId,
                              to: _vm.start,
                              from: _vm.end,
                              height: "450",
                              annotations: "1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c("CDataTable", {
                                attrs: {
                                  hover: "",
                                  items: _vm.eventData,
                                  fields: _vm.fields,
                                  sorter: "",
                                  dense: "",
                                  responsive: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "start",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.showDate(item)) +
                                              " "
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "event",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "td",
                                          [
                                            _vm.showStatus(item)
                                              ? _c(
                                                  "CBadge",
                                                  {
                                                    attrs: {
                                                      color: _vm.showStatus(
                                                        item
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "10pt"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.event)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _c("p", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.event) +
                                                      " "
                                                  )
                                                ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "show_details",
                                    fn: function(ref) {
                                      var item = ref.item
                                      var index = ref.index
                                      return [
                                        _c(
                                          "td",
                                          { staticClass: "py-2" },
                                          [
                                            _c(
                                              "CButton",
                                              {
                                                attrs: {
                                                  color: "danger",
                                                  variant: "outline",
                                                  square: "",
                                                  size: "sm"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.getJobById(
                                                      item,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      Boolean(item._toggled)
                                                        ? "less"
                                                        : "more"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }