var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "job-report-view" },
    [
      _vm.geofenceMessage
        ? _c(
            "CCard",
            {
              staticClass: "admin-card",
              attrs: {
                collapsed: true,
                collapsible: true,
                "border-color": "warning"
              }
            },
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-bell" } }),
                  _c("span", [
                    _vm._v(" Event: " + _vm._s(_vm.geofenceMessage) + " ")
                  ])
                ],
                1
              ),
              _c(
                "CCardBody",
                {
                  on: {
                    dblclick: function($event) {
                      return _vm.handleViewClick(_vm.item)
                    }
                  }
                },
                [
                  _c(
                    "CMedia",
                    {
                      attrs: {
                        "aside-image-props": {
                          src: "/images/Mapbox_dark_sample.jpg",
                          height: 100,
                          width: 175
                        }
                      }
                    },
                    [
                      _c("h4", [
                        _c("span", { staticClass: "p-0" }, [
                          _vm._v(_vm._s(_vm.geofenceMessage))
                        ])
                      ]),
                      _c(
                        "span",
                        { staticClass: "p-0" },
                        [
                          _c("CGeocode", {
                            attrs: {
                              lat: _vm.siteLocation.lat,
                              lon: _vm.siteLocation.lng
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("JobSummary", {
        attrs: {
          jobSummaryData: _vm.jobSummaryData,
          truckID: _vm.truckNumber,
          siteAddress: _vm.siteAddress,
          location: _vm.siteLocation
        }
      }),
      _c("PerformanceSummary", {
        staticClass: "pt-2",
        attrs: { performanceSummaryData: _vm.performanceSummaryData }
      }),
      _c("JobDataView", { ref: "jobdata", attrs: { orgId: _vm.$auth.orgId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }