var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        {},
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                { staticClass: "mb-0 pb-0", attrs: { id: "card-header" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "card-title",
                      attrs: { id: "summary-title" }
                    },
                    [_vm._v("Performance Summary")]
                  )
                ]
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CRow",
                    [
                      _c("CCol", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "card-text data-heading card-text-light"
                          },
                          [_vm._v(" Wand Used Correctly")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "cart-text-dark data-display",
                            attrs: { id: "wand-status" }
                          },
                          [_vm._v(_vm._s(_vm.wandUsedCorrectly))]
                        ),
                        _c(
                          "p",
                          { staticClass: "card-text-light data-heading" },
                          [_vm._v(" Total Wand Drops")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "data-display",
                            attrs: { id: "total-wand-drops" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.performanceSummaryData.totalWandDrops)
                            )
                          ]
                        )
                      ]),
                      _c("CCol", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "card-text card-text-light data-heading"
                          },
                          [_vm._v(" Safe Pressure Range")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "cart-text-dark data-display",
                            attrs: { id: "wand-status" }
                          },
                          [_vm._v(_vm._s(_vm.safePressureRange))]
                        ),
                        _c(
                          "p",
                          { staticClass: "card-text-light data-heading" },
                          [_vm._v("Total E-Stops")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "data-display",
                            attrs: { id: "total-e-stops" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.performanceSummaryData.totalEstops)
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    [
                      _c("CScoreCard", {
                        staticClass: "p-0",
                        attrs: {
                          score: _vm.performanceSummaryData.score,
                          title: "Safety Score",
                          message: _vm.performanceSummaryData.message
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }